import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"

import SEO from "./../components/seo"
import Layout from "../components/layout"
import slugify from "slugify"
import ImgPa from "../components/img/imgPa"
import { getPrix } from "../services/panier"
import { useSelector } from "react-redux"

const AccessoiresPage = (props) => {
  const typesPassePartout = props.pageContext.accessoires.reduce((acc, cur) => (acc.includes(cur.pat_id) ? acc : [...acc, cur.pat_id]), [])
  const pageProduit = useSelector((s) => s.PageProduit)
  const photosR = useSelector((s) => s.photosR)
  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title="Accroche cadre"
          description="Pochettes de protection, tournettes de fixation chassis, crochets de suspension, rouleaux de kraft, fibranne, adhésifs, ..."
          canonical={`${process.env.GATSBY_URL_ACTUEL}/accessoires/`}
          jsonLd={[
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Accueil",
                  item: process.env.GATSBY_URL_ACTUEL,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Accessoires",
                  item: `${process.env.GATSBY_URL_ACTUEL}/accessoires/`,
                },
              ],
            },
          ]}
        />
        <nav className="breadcrumb mb-0 mt-4">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <span className="pl-3">Accessoires</span>
            </li>
          </ul>
        </nav>

        <div className="columns" style={{}}>
          <div className="column is-9">
            <h1>Accroche cadre</h1>
            {typesPassePartout.map((id_type) => (
              <div key={id_type} id={`menu-${id_type}`}>
                <div className="h2">{props.pageContext.accessoires.find((u) => u.pat_id === id_type).pat_libelle}</div>
                <div className="columns is-multiline is-centered">
                  {props.pageContext.accessoires.map((node, index) =>
                    node.pat_id === id_type ? (
                      <div key={index} className="column is-narrow has-text-centered accessoire">
                        <Link to={`/accessoires/${slugify(node.pa_libelle)}-${node.pa_id}/`}>
                          <div className="columns is-centered">
                            <div className="name">
                              {node.pat_id !== 26
                                ? node.pa_libelle.replace(new RegExp(/(toile| pour chassis peinture|Rouleau de |largeur)/gi), "")
                                : `Pochette ${node.largeur} x ${node.hauteur} cm`}
                            </div>
                            <div className="price">
                              {new Intl.NumberFormat("fr-FR", {
                                style: "currency",
                                currency: "EUR",
                              }).format(getPrix(node, pageProduit, props.pageContext.pa, photosR))}
                            </div>
                          </div>

                          <div style={{ minWidth: 200, maxWidth: 200, marginLeft: "auto", marginRight: "auto" }}>
                            <ImgPa id={node.pa_id} style={{ maxWidth: 200 }} />
                          </div>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="column is-3 is-fullheight pt-5">
            <ul className="menuCategory">
              {typesPassePartout.map((id_type) => (
                <a key={id_type} href={`#menu-${id_type}`}>
                  <li>
                    <div className={`navbar-item disableOutline`} style={{ cursor: "pointer" }}>
                      {props.pageContext.accessoires
                        .find((u) => u.pat_id === id_type)
                        .pat_libelle.replace(new RegExp(/(: finition bordage chassis peinture|gommés et adhésifs|qualité cristal|protection)/gi), "")}
                    </div>
                  </li>
                </a>
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    </>
  )
}

AccessoiresPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default AccessoiresPage
